<article class="tile-card-deposit card card-1" matRipple>
    <div class="menuCardContainer">
        <div class="inner-card">
            <div class="cardContainer">
                <div *ngIf="records && records.length > 0">
                    <table style="width: 100%; border-spacing: 0;">
                        <tr>
                            <th>{{deposit.data.amount}}</th>
                            <th class="th-spacing align-right">%</th>
                            <th class="th-spacing align-right">Mass</th>
                            <th class="td-spacing align-right">vol.</th>
                            <th class="th-spacing align-right">r. vol.</th>
                            <th class="th-spacing align-right">r. value</th>
                        </tr>
                        <tr *ngFor="let row of records"  [style.color]="getRowColor(row)">
                            <td class="">{{row.data.name}}</td>
                            <td class="td-spacing align-right">{{row.data.percent}}</td>
                            <td class="td-spacing align-right">{{row.data.mass}}</td>
                            <td class="td-spacing align-right">{{row.data.raw_volume}}</td>
                            <td class="td-spacing align-right">{{row.data.refined_volume}}</td>
                            <td class="td-spacing align-right">{{(row.data.refined_value / 1000) | number:'1.0-1'}}k</td>
                        </tr>

                        <tr [style.color]="'#00c853'">
                            <td class="bottom-row"></td>
                            <td class="td-spacing align-right bottom-row">{{sum(records, 'percent')| number:'1.0-1'}}</td>
                            <td class="td-spacing align-right bottom-row">{{sum(records, 'mass')| number:'1.0-1'}}</td>
                            <td class="td-spacing align-right bottom-row">{{sum(records, 'raw_volume') | number:'1.0-1'}}</td>
                            <td class="td-spacing align-right bottom-row">{{sum(records, 'refined_volume') | number:'1.0-1'}}</td>
                            <td class="td-spacing align-right bottom-row">{{(sum(records, 'refined_value') / 1000) | number:'1.0-1'}}k</td>
                        </tr>
                        <tr>
                            <td colspan="6" class="align-left bottom-row"><pre [innerHTML]="deposit.data.deposit_comment"></pre></td>
                        </tr>
                        <tr>
                            <td class="bottom-row" colspan="3">Scout: <span class="player-name">{{deposit.data.miner_name}}</span></td>
                            <td class="bottom-row" colspan="3">Miner: <span class="player-name">{{deposit.data.scout_name}}</span></td>
                        </tr>
                        <tr *ngIf="deposit.data.coordinate_location">
                            <td class="bottom-row" colspan="6">{{deposit.data.coordinate_location}}, {{deposit.data.coordinate_x}},
                                {{deposit.data.coordinate_y}},{{deposit.data.coordinate_d}}°</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</article>
