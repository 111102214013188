import {LapTimeOverview} from './lap-time-overview';
import {AppSettings} from '../config/AppSettings';

export class LapTimeSeriesOverview extends LapTimeOverview {
    /**
     * override default columns
     */
    public setColumns() {
        this.column_definition = [
            {id: 'points', label: 'Points', visible: true, noclick: false, disable_ripple: true},
            {id: 'track_id', label: 'Track', visible: true, noclick: false, disable_ripple: true},
            {id: 'race_type_id', label: 'Type', visible: true, noclick: false, disable_ripple: true},
            {id: 'user_id', label: 'Player', visible: true, noclick: false, disable_ripple: true},
            {id: 'lap_time', label: 'Lap time', visible: true, noclick: false, disable_ripple: true},
            {id: 'vehicle_id', label: 'Vehicle', visible: true, noclick: false, disable_ripple: true},
            {id: 'man_id', label: 'Manufacturer', visible: true, noclick: false, disable_ripple: true},
            {id: 'vehicle_type', label: 'Vehicle Type', visible: true, noclick: false, disable_ripple: true},
            {id: 'vehicle_class', label: 'Vehicle Class', visible: true, noclick: false, disable_ripple: true},
            {id: 'fy_mass', label: 'Mass', visible: true, noclick: false, disable_ripple: true},
            {id: 'fy_scmSpeed', label: 'SCM Speed', visible: true, noclick: false, disable_ripple: true},
            {id: 'fy_scmSpeedAcceleration', label: 'Acceleration', visible: true, noclick: false, disable_ripple: true},
            {id: 'fy_scmSpeedDecceleration', label: 'Decceleration', visible: true, noclick: false, disable_ripple: true},
            {id: 'control_stick_left', label: 'Stick left', visible: true, noclick: false, disable_ripple: true},
            {id: 'control_stick_right', label: 'Stick right', visible: true, noclick: false, disable_ripple: true},
            {id: 'control_throttle', label: 'Throttle', visible: true, noclick: false, disable_ripple: true},
            {id: 'control_pedals', label: 'Pedals', visible: true, noclick: false, disable_ripple: true},
            {id: 'control_keyboard', label: 'Keyboard', visible: true, noclick: false, disable_ripple: true},
            {id: 'control_mouse', label: 'Mouse', visible: true, noclick: false, disable_ripple: true},
            {id: 'assist_coupled', label: ' coupled', visible: true, noclick: false, disable_ripple: true},
            {id: 'assist_gravity_compensation', label: 'gravity compensation', visible: true, noclick: false, disable_ripple: true},
            {id: 'assist_gsafe', label: 'gsafe', visible: true, noclick: false, disable_ripple: true},
            {id: 'assist_proximity', label: 'proximity assist', visible: true, noclick: false, disable_ripple: true},
            {id: 'insert_ts', label: 'Inserted', visible: true, noclick: false, disable_ripple: true},
            {id: 'confirmed', label: 'Confirmed', visible: true, noclick: false, disable_ripple: true}
        ];
    }

    /**
     * override default fields
     */
    public setEditFields() {
        this.edit_fields = [
            {
                caption: 'Track',
                class: 'card card-1 padding',
                field_class: '',
                fields: [
                    {
                        type: 'number',
                        name: 'points',
                        disabled: true
                    },
                    {
                        type: 'select',
                        name: 'track_id',
                        options: this.select_boxes['race_track_select'],
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'select',
                        name: 'race_type_id',
                        options: this.select_boxes['race_type_select'],
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'autocomplete',
                        name: 'user_id',
                        options: this.select_boxes['sc_user_select'],
                        disabled: true,
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'time',
                        name: 'lap_time',
                        step: 0.01
                    },
                    {
                        type: 'select',
                        name: 'vehicle_id',
                        options: this.select_boxes['vehicle_select'],
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'select',
                        name: 'man_id',
                        options: this.select_boxes['manufacturer_select'],
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'select',
                        name: 'vehicle_type',
                        options: this.select_boxes['vehicle_type_select'],
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'select',
                        name: 'vehicle_class',
                        options: this.select_boxes['vehicle_class_select'],
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'number',
                        name: 'fy_mass',
                        disabled: true,
                    },
                    {
                        type: 'number',
                        name: 'fy_scmSpeed',
                        disabled: true,
                    },
                    {
                        type: 'number',
                        name: 'fy_scmSpeedAcceleration',
                        disabled: true,
                    },
                    {
                        type: 'number',
                        name: 'fy_scmSpeedDecceleration',
                        disabled: true,
                    },
                    {
                        type: 'select',
                        options: this.select_boxes['controls_select'],
                        name: 'controls',
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'datetime',
                        name: 'insert_ts'
                    }
                ]
            },
            {
                caption: 'Controls (optional)',
                class: 'card card-1 padding',
                field_class: '',
                fields: [
                    {
                        type: 'checkbox',
                        name: 'control_stick_left'
                    },
                    {
                        type: 'checkbox',
                        name: 'control_stick_right'
                    },
                    {
                        type: 'checkbox',
                        name: 'control_throttle'
                    },
                    {
                        type: 'checkbox',
                        name: 'control_pedals'
                    },
                    {
                        type: 'checkbox',
                        name: 'control_keyboard'
                    },
                    {
                        type: 'checkbox',
                        name: 'control_mouse'
                    }
                ]
            },
            {
                caption: 'Assistants (optional)',
                class: 'card card-1 padding',
                field_class: '',
                fields: [
                    {
                        type: 'checkbox',
                        name: 'assist_coupled'
                    },
                    {
                        type: 'checkbox',
                        name: 'assist_gravity_compensation'
                    },
                    {
                        type: 'checkbox',
                        name: 'assist_gsafe'
                    },
                    {
                        type: 'checkbox',
                        name: 'assist_proximity'
                    }
                ]
            },
            {
                caption: 'Confirm',
                class: 'card card-1 padding',
                field_class: '',
                fields: [
                    {
                        type: 'checkbox',
                        name: 'confirmed',
                        restricted_groups: [AppSettings.group_rally_admin],
                        restrict_ony_edit: true
                    }
                ]
            }
        ];
        super.setEditFields();
    }
    public loadStores(ext_obj?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.stores['lap_times'] = this.storeService.getStore('lap_times');

            this.main_store = this.stores['lap_times'];

            this.stores['race_tracks'] = this.storeService.getStore('race_tracks');
            this.stores['race_types'] = this.storeService.getStore('race_types');
            this.stores['vehicle'] = this.storeService.getStore('vehicle');
            this.stores['manufacturer'] = this.storeService.getStore('manufacturer');
            this.stores['vehicle_types'] = this.storeService.getStore('vehicle_types');
            this.stores['vehicle_class'] = this.storeService.getStore('vehicle_class');
            this.stores['sc_user'] = this.storeService.getStore('sc_user');

            const selectFilter = [];
            selectFilter.push('first=0');
            selectFilter.push('rows=1000');

            const filter = [];
            filter.push('filter[track_id][0][mode]=equals');
            filter.push('filter[track_id][0][operator]=and');
            filter.push('filter[track_id][0][value]=' + this.custom_obj.track_id);
            filter.push('filter[race_type_id][0][mode]=equals');
            filter.push('filter[race_type_id][0][operator]=and');
            filter.push('filter[race_type_id][0][value]=' + this.custom_obj.race_type_id);
            filter.push('filter[series_id][0][mode]=equals');
            filter.push('filter[series_id][0][operator]=and');
            filter.push('filter[series_id][0][value]=' + this.custom_obj.series_id);

            const stores = [];
            stores.push(this.stores['sc_user'].loadStore('?' + selectFilter.join('&')));
            stores.push(this.stores['lap_times'].loadStore('?' + filter.join('&')));
            stores.push(this.stores['race_tracks'].loadStore('?' + selectFilter.join('&')));
            stores.push(this.stores['race_types'].loadStore('?' + selectFilter.join('&')));
            stores.push(this.stores['vehicle'].loadStore('?' + selectFilter.join('&')));
            stores.push(this.stores['manufacturer'].loadStore('?' + selectFilter.join('&')));
            stores.push(this.stores['vehicle_types'].loadStore('?' + selectFilter.join('&')));
            stores.push(this.stores['vehicle_class'].loadStore('?' + selectFilter.join('&')));

            Promise.all(stores).then((result) => {
                resolve(true);
            });
        });
    }
}
